import React, {
    useState,
    useEffect,
    ChangeEventHandler,
    useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './TargetGroupSettings.module.css';
import {
    Button,
    majorScale,
    Pane,
    Popover,
    Position as SettingsPosition,
    SettingsIcon,
    CogIcon,
    SmallCrossIcon,
    Tab,
    Tablist,
    Select,
    Switch,
    TextInput,
    Spinner,
    SavedIcon,
    ControlIcon,
    Textarea,
    NotificationsIcon,
} from 'evergreen-ui';
import AppButton from 'components/AppButton/AppButton';
import { formatDate } from 'utils/time';
import { actions as targetGroupActions } from 'store/targetGroups/targetGroups.actions';
import { selectTargetGroupState } from 'store/targetGroups/targetGroups.selectors';
import { selectAssessmentsById } from 'store/assessments/assessments.selectors';
import { Link } from 'react-router-dom';
import { EmailsForm } from '../../../components/EmailsForm/EmailsForm';

interface TargetGroupSettingsProps {
    assessmentId: string;
    groupId: string;
    registrationUrl: string;
    companySlug: string;
}
export const TargetGroupSettings = (props: TargetGroupSettingsProps) => {
    const { assessmentId, groupId, companySlug, registrationUrl } = props;
    const dispatch = useDispatch();
    const [savingResultTimeoutId, setSavingResultTimeoutId] =
        useState<NodeJS.Timeout>();

    const assessmentsById = useSelector(selectAssessmentsById);
    const { name, language } = assessmentsById[assessmentId]
        ? assessmentsById[assessmentId].data
        : { name: '', language: '' };

    const stateTargetGroups = useSelector(selectTargetGroupState);
    const stateTargetGroup = stateTargetGroups.byId[groupId];
    const { updatingConfig, error } = stateTargetGroup
        ? stateTargetGroup
        : { updatingConfig: undefined, error: null };
    const targetGroup = stateTargetGroup ? stateTargetGroup.data : undefined;

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [tabs] = useState(['General', 'Settings', 'Notifications']);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [displaySuccess, setDisplaySuccess] = useState<boolean>(false);
    const [targetGroupName, setTargetGroupName] = useState<string>('');
    const [targetGroupDescription, setTargetGroupDescription] =
        useState<string>('');
    const [isTargetGroupPublic, setIsTargetGroupPublic] =
        useState<boolean>(false);
    const [autoSendReportEmails, setAutoSendReportEmails] =
        useState<boolean>(false);
    const [verifyEmails, setVerifyEmails] = useState<boolean>(true);
    const [scanCompletionNotification, setScanCompletionNotification] =
        useState<boolean>(true);
    const [
        scanCompletionNotificationEmails,
        setScanCompletionNotificationEmails,
    ] = useState<string>('');
    const [
        isScanCompletionNotificationEmailsValid,
        setScanCompletionNotificationEmailsValid,
    ] = useState(false);
    const [reportGenerationNotification, setReportGenerationNotification] =
        useState<boolean>(true);
    const [
        reportGenerationNotificationEmails,
        setReportGenerationNotificationEmails,
    ] = useState<string>('');
    const [
        isReportGenerationNotificationEmailsValid,
        setReportGenerationNotificationEmailsValid,
    ] = useState(false);

    const onChangeName: ChangeEventHandler<HTMLInputElement> = (event) => {
        setTargetGroupName(event.target.value);
    };
    const onChangeDescription: ChangeEventHandler<HTMLInputElement> = (
        event,
    ) => {
        setTargetGroupDescription(event.target.value);
    };
    const onChangeRegistrationMethods: ChangeEventHandler<HTMLSelectElement> = (
        event,
    ) => {
        setIsTargetGroupPublic(event.target.value === 'invite-public');
    };
    const onChangeVerifyEmails: ChangeEventHandler<HTMLInputElement> = (
        event,
    ) => {
        setVerifyEmails(event.target.checked);
    };
    const onChangeAutoSendReports: ChangeEventHandler<HTMLInputElement> = (
        event,
    ) => {
        setAutoSendReportEmails(event.target.checked);
    };
    const onChangeScanCompletionNotification: ChangeEventHandler<
        HTMLInputElement
    > = (event) => {
        setScanCompletionNotification(event.target.checked);
    };
    const onChangeReportGenerationNotification: ChangeEventHandler<
        HTMLInputElement
    > = (event) => {
        setReportGenerationNotification(event.target.checked);
    };
    const onSettingsClosed = () => {
        setFieldsFromState();
        setSelectedIndex(0);
    };
    const setFieldsFromState = useCallback(() => {
        if (targetGroup) {
            setTargetGroupName(targetGroup.name);
            setTargetGroupDescription(targetGroup.description);
            setIsTargetGroupPublic(
                targetGroup.config?.is_public
                    ? targetGroup.config.is_public
                    : false,
            );
            setVerifyEmails(
                typeof targetGroup.config?.verify_emails === 'boolean'
                    ? targetGroup.config.verify_emails
                    : true,
            );
            setAutoSendReportEmails(
                targetGroup.config?.auto_send_reports
                    ? targetGroup.config.auto_send_reports
                    : false,
            );
            setScanCompletionNotification(
                typeof targetGroup.config?.scan_completion_notification ===
                    'boolean'
                    ? targetGroup.config.scan_completion_notification
                    : false,
            );
            setScanCompletionNotificationEmails(
                targetGroup.config?.scan_completion_notification_emails
                    ? targetGroup.config.scan_completion_notification_emails
                    : '',
            );
            setReportGenerationNotification(
                typeof targetGroup.config?.report_generation_notification ===
                    'boolean'
                    ? targetGroup.config.report_generation_notification
                    : false,
            );
            setReportGenerationNotificationEmails(
                targetGroup.config?.report_generation_notification_emails
                    ? targetGroup.config.report_generation_notification_emails
                    : '',
            );
        }
    }, [targetGroup]);

    const onSettingsSave = () => {
        setIsSaving(true);
        dispatch(
            targetGroupActions.updateTargetGroupConfig(
                companySlug,
                groupId,
                {
                    name: targetGroupName,
                    description: targetGroupDescription,
                    is_public: isTargetGroupPublic,
                    verify_emails: verifyEmails,
                    auto_send_reports: autoSendReportEmails,
                },
            ),
        );
    };

    const onNotificationsSave = () => {
        setIsSaving(true);
        dispatch(
            targetGroupActions.updateTargetGroupConfig(
                companySlug,
                groupId,
                {
                    scan_completion_notification: scanCompletionNotification,
                    scan_completion_notification_emails:
                        scanCompletionNotificationEmails,
                    report_generation_notification:
                        reportGenerationNotification,
                    report_generation_notification_emails:
                        reportGenerationNotificationEmails,
                },
            ),
        );
    };
    const handleScanCompletionEmailsChange = (
        emails: string,
        isValid: boolean,
    ) => {
        setScanCompletionNotificationEmails(emails);
        setScanCompletionNotificationEmailsValid(
            isValid || (!scanCompletionNotification && emails === ''),
        );
    };
    const handleReportGenerationEmailsChange = (
        emails: string,
        isValid: boolean,
    ) => {
        setReportGenerationNotificationEmails(emails);
        setReportGenerationNotificationEmailsValid(
            isValid || (!reportGenerationNotification && emails === ''),
        );
    };

    useEffect(() => {
        setFieldsFromState();
    }, [setFieldsFromState, targetGroup]);

    useEffect(() => {
        if (isSaving && updatingConfig === false && !error) {
            // Clear previous save timeout if exists
            if (savingResultTimeoutId) {
                clearTimeout(savingResultTimeoutId);
            }

            setIsSaving(false);
            setDisplaySuccess(true);
            const timeoutId = setTimeout(() => setDisplaySuccess(false), 5000);
            setSavingResultTimeoutId(timeoutId);
        } else {
            setDisplaySuccess(false);
        }
    }, [updatingConfig, error]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={styles.target_group_settings}>
            <Popover
                position={SettingsPosition.TOP_LEFT}
                shouldCloseOnExternalClick={false}
                onClose={onSettingsClosed}
                // @ts-ignore
                content={({ close }) => (
                    <>
                        <div
                            className={
                                styles.target_group_settings__pane__close_icon
                            }>
                            <SmallCrossIcon size={19} onClick={close} />
                        </div>
                        <Pane
                            maxWidth="60vw"
                            maxHeight="70vh"
                            overflowY="auto"
                            display="flex"
                            padding={majorScale(2)}>
                            <Tablist marginY={22} marginX={18} flexBasis={130}>
                                {tabs.map((tab, index) => (
                                    <Tab
                                        key={tab}
                                        id={tab}
                                        direction="vertical"
                                        onSelect={() => setSelectedIndex(index)}
                                        isSelected={index === selectedIndex}
                                        aria-controls={`panel-${tab}`}
                                        className={
                                            styles.target_group_settings__configuration_tab
                                        }>
                                        {tab === 'Notifications' && (
                                            <NotificationsIcon
                                                className={styles.tab_list_icon}
                                            />
                                        )}
                                        {tab === 'Settings' && (
                                            <CogIcon
                                                className={styles.tab_list_icon}
                                            />
                                        )}
                                        {tab === 'General' && (
                                            <ControlIcon
                                                className={styles.tab_list_icon}
                                            />
                                        )}
                                        {tab}
                                    </Tab>
                                ))}
                            </Tablist>
                            <Pane padding={22} flex="1">
                                {tabs.map((tab, index) => {
                                    switch (tab) {
                                        case 'Settings':
                                            return (
                                                <Pane
                                                    key={tab}
                                                    id={`panel-${tab}`}
                                                    role="tabpanel"
                                                    aria-labelledby={tab}
                                                    aria-hidden={
                                                        index !== selectedIndex
                                                    }
                                                    className={
                                                        styles.target_group_settings__pane__configuration
                                                    }
                                                    display={
                                                        index === selectedIndex
                                                            ? 'block'
                                                            : 'none'
                                                    }>
                                                    <table>
                                                        <colgroup>
                                                            <col
                                                                className={
                                                                    styles.setting_key
                                                                }
                                                            />
                                                            <col
                                                                className={
                                                                    styles.setting_value
                                                                }
                                                            />
                                                        </colgroup>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <div>
                                                                        <span
                                                                            className={
                                                                                styles.target_group_settings__pane__configuration__config_label
                                                                            }>
                                                                            Name
                                                                        </span>
                                                                        <p>
                                                                            Name
                                                                            of
                                                                            this
                                                                            group{' '}
                                                                        </p>{' '}
                                                                        <p>
                                                                            <span
                                                                                className={
                                                                                    styles.target_group_settings__pane__configuration__bold_teal_text
                                                                                }>
                                                                                Group
                                                                                names
                                                                                are
                                                                                visible
                                                                                to
                                                                                candidates
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <TextInput
                                                                        onChange={
                                                                            onChangeName
                                                                        }
                                                                        value={
                                                                            targetGroupName
                                                                        }
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div>
                                                                        <span
                                                                            className={
                                                                                styles.target_group_settings__pane__configuration__config_label
                                                                            }>
                                                                            Description
                                                                        </span>
                                                                        <p>
                                                                            This
                                                                            is
                                                                            an
                                                                            optional
                                                                            description
                                                                            about
                                                                            this
                                                                            group,
                                                                            for
                                                                            your
                                                                            own
                                                                            reference.
                                                                            Candidates
                                                                            can
                                                                            not
                                                                            see
                                                                            this
                                                                            description.
                                                                        </p>{' '}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <Textarea
                                                                        onChange={
                                                                            onChangeDescription
                                                                        }
                                                                        value={
                                                                            targetGroupDescription
                                                                        }
                                                                        style={{
                                                                            width: '100%',
                                                                            resize: 'none',
                                                                        }}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div>
                                                                        <span
                                                                            className={
                                                                                styles.target_group_settings__pane__configuration__config_label
                                                                            }>
                                                                            Registration
                                                                            methods
                                                                        </span>
                                                                        <p>
                                                                            Candidate
                                                                            registration
                                                                            methods
                                                                            for
                                                                            this
                                                                            group
                                                                        </p>
                                                                        <p>
                                                                            <span
                                                                                className={
                                                                                    styles.target_group_settings__pane__configuration__description_title
                                                                                }>
                                                                                invites
                                                                                &
                                                                                public
                                                                                registration:
                                                                            </span>
                                                                            Recruiters
                                                                            can
                                                                            invite
                                                                            candidates
                                                                            using
                                                                            the
                                                                            dashboard.
                                                                            Anyone
                                                                            with
                                                                            the
                                                                            registration
                                                                            link
                                                                            can
                                                                            register
                                                                            to
                                                                            the
                                                                            group.
                                                                        </p>
                                                                        <p>
                                                                            <span
                                                                                className={
                                                                                    styles.target_group_settings__pane__configuration__description_title
                                                                                }>
                                                                                invite
                                                                                only:
                                                                            </span>
                                                                            Only
                                                                            the
                                                                            recruiters
                                                                            can
                                                                            invite
                                                                            candidates
                                                                            using
                                                                            the
                                                                            dashboard.
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <Select
                                                                        width="100%"
                                                                        value={
                                                                            isTargetGroupPublic
                                                                                ? 'invite-public'
                                                                                : 'invite'
                                                                        }
                                                                        onChange={
                                                                            onChangeRegistrationMethods
                                                                        }>
                                                                        <option value="invite">
                                                                            invite
                                                                            only
                                                                        </option>
                                                                        <option value="invite-public">
                                                                            invites
                                                                            &
                                                                            public
                                                                            registration
                                                                        </option>
                                                                    </Select>
                                                                </td>
                                                            </tr>
                                                            {isTargetGroupPublic && (
                                                                <tr>
                                                                    <td>
                                                                        <div>
                                                                            <span
                                                                                className={
                                                                                    styles.target_group_settings__pane__configuration__config_label
                                                                                }>
                                                                                Registration
                                                                                URL
                                                                            </span>
                                                                            <p>
                                                                                Candidates
                                                                                can
                                                                                register
                                                                                using
                                                                                this
                                                                                link.
                                                                                Requires
                                                                                public
                                                                                registration
                                                                                enabled
                                                                                via
                                                                                "Registration
                                                                                methods"
                                                                                settings.
                                                                            </p>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <span>
                                                                            <a
                                                                                href={
                                                                                    registrationUrl
                                                                                }
                                                                                target="_blank"
                                                                                className={
                                                                                    styles.link
                                                                                }
                                                                                rel="noreferrer">
                                                                                Public
                                                                                registration
                                                                                link
                                                                            </a>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            <tr>
                                                                <td>
                                                                    <div>
                                                                        <span
                                                                            className={
                                                                                styles.target_group_settings__pane__configuration__config_label
                                                                            }>
                                                                            Email
                                                                            address
                                                                            verification
                                                                        </span>
                                                                        <p>
                                                                            If
                                                                            enabled,
                                                                            new
                                                                            candidates
                                                                            who
                                                                            register
                                                                            via
                                                                            the
                                                                            public
                                                                            registration
                                                                            link
                                                                            will
                                                                            need
                                                                            to
                                                                            verify
                                                                            their
                                                                            email
                                                                            address
                                                                            before
                                                                            they
                                                                            can
                                                                            access
                                                                            the
                                                                            scan.
                                                                            They
                                                                            will
                                                                            receive
                                                                            an
                                                                            email
                                                                            containing
                                                                            the
                                                                            verification
                                                                            link,
                                                                            and
                                                                            once
                                                                            they
                                                                            verify
                                                                            their
                                                                            email
                                                                            they
                                                                            will
                                                                            receive
                                                                            an
                                                                            invitation
                                                                            to
                                                                            complete
                                                                            the
                                                                            scan.
                                                                        </p>
                                                                        <p>
                                                                            We
                                                                            recommend
                                                                            keeping
                                                                            this
                                                                            option
                                                                            enabled
                                                                            on
                                                                            groups
                                                                            with
                                                                            public
                                                                            registration
                                                                            enabled.
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <Switch
                                                                        height={
                                                                            24
                                                                        }
                                                                        checked={
                                                                            isTargetGroupPublic &&
                                                                            verifyEmails
                                                                        }
                                                                        onChange={
                                                                            onChangeVerifyEmails
                                                                        }
                                                                        className={
                                                                            styles.switch
                                                                        }
                                                                        disabled={
                                                                            !isTargetGroupPublic
                                                                        }
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div>
                                                                        <span
                                                                            className={
                                                                                styles.target_group_settings__pane__configuration__config_label
                                                                            }>
                                                                            Send
                                                                            reports
                                                                            automatically
                                                                        </span>
                                                                        <p>
                                                                            Send
                                                                            the
                                                                            report
                                                                            emails
                                                                            automatically
                                                                            to
                                                                            the
                                                                            candidates
                                                                            after
                                                                            analysis
                                                                            is
                                                                            finished.
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <Switch
                                                                        height={
                                                                            24
                                                                        }
                                                                        checked={
                                                                            autoSendReportEmails
                                                                        }
                                                                        onChange={
                                                                            onChangeAutoSendReports
                                                                        }
                                                                        className={
                                                                            styles.switch
                                                                        }
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div
                                                        className={
                                                            styles.submit_wrap
                                                        }>
                                                        <span
                                                            className={
                                                                styles.save_result
                                                            }>
                                                            {updatingConfig ===
                                                            true ? (
                                                                <Spinner
                                                                    size={15}
                                                                />
                                                            ) : displaySuccess ? (
                                                                'Saved!'
                                                            ) : (
                                                                ''
                                                            )}
                                                        </span>
                                                        <AppButton
                                                            icon={SavedIcon}
                                                            onClick={
                                                                onSettingsSave
                                                            }
                                                            width={'120px'}
                                                            isDisabled={
                                                                updatingConfig
                                                            }>
                                                            Save
                                                        </AppButton>
                                                    </div>
                                                </Pane>
                                            );
                                        case 'Notifications':
                                            return (
                                                <Pane
                                                    key={tab}
                                                    id={`panel-${tab}`}
                                                    role="tabpanel"
                                                    aria-labelledby={tab}
                                                    aria-hidden={
                                                        index !== selectedIndex
                                                    }
                                                    className={
                                                        styles.target_group_settings__pane__configuration
                                                    }
                                                    display={
                                                        index === selectedIndex
                                                            ? 'block'
                                                            : 'none'
                                                    }>
                                                    <table
                                                        className={
                                                            styles.target_group_settings__pane__configuration__notifications_table
                                                        }>
                                                        <colgroup>
                                                            <col
                                                                className={
                                                                    styles.setting_key
                                                                }
                                                            />
                                                            <col
                                                                className={
                                                                    styles.setting_value
                                                                }
                                                            />
                                                        </colgroup>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <div>
                                                                        <span
                                                                            className={
                                                                                styles.target_group_settings__pane__configuration__config_label
                                                                            }>
                                                                            Scan
                                                                            completion
                                                                            notification
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <Switch
                                                                        height={
                                                                            24
                                                                        }
                                                                        checked={
                                                                            scanCompletionNotification
                                                                        }
                                                                        onChange={
                                                                            onChangeScanCompletionNotification
                                                                        }
                                                                        className={
                                                                            styles.switch
                                                                        }
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr
                                                                style={{
                                                                    verticalAlign:
                                                                        'top',
                                                                }}>
                                                                <td>
                                                                    <div>
                                                                        <span
                                                                            className={
                                                                                styles.target_group_settings__pane__configuration__config_label
                                                                            }>
                                                                            Scan
                                                                            completion
                                                                            notification
                                                                            emails
                                                                        </span>
                                                                        <p>
                                                                            List
                                                                            of
                                                                            emails
                                                                            that
                                                                            will
                                                                            receive
                                                                            notification
                                                                            when
                                                                            a
                                                                            scan
                                                                            is
                                                                            completed
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <EmailsForm
                                                                        onEmailsChange={
                                                                            handleScanCompletionEmailsChange
                                                                        }
                                                                        initialEmails={
                                                                            scanCompletionNotificationEmails
                                                                        }
                                                                        emailsDisabled={
                                                                            !scanCompletionNotification
                                                                        }
                                                                        setToggle={
                                                                            setScanCompletionNotification
                                                                        }
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div>
                                                                        <span
                                                                            className={
                                                                                styles.target_group_settings__pane__configuration__config_label
                                                                            }>
                                                                            Report
                                                                            generation
                                                                            notification
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <Switch
                                                                        height={
                                                                            24
                                                                        }
                                                                        checked={
                                                                            reportGenerationNotification
                                                                        }
                                                                        onChange={
                                                                            onChangeReportGenerationNotification
                                                                        }
                                                                        className={
                                                                            styles.switch
                                                                        }
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr
                                                                style={{
                                                                    verticalAlign:
                                                                        'top',
                                                                }}>
                                                                <td>
                                                                    <div>
                                                                        <span
                                                                            className={
                                                                                styles.target_group_settings__pane__configuration__config_label
                                                                            }>
                                                                            Report
                                                                            generation
                                                                            notification
                                                                            emails
                                                                        </span>
                                                                        <p>
                                                                            List
                                                                            of
                                                                            emails
                                                                            that
                                                                            will
                                                                            receive
                                                                            notification
                                                                            when
                                                                            a
                                                                            report
                                                                            is
                                                                            generated
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <EmailsForm
                                                                        onEmailsChange={
                                                                            handleReportGenerationEmailsChange
                                                                        }
                                                                        initialEmails={
                                                                            reportGenerationNotificationEmails
                                                                        }
                                                                        emailsDisabled={
                                                                            !reportGenerationNotification
                                                                        }
                                                                        setToggle={
                                                                            setReportGenerationNotification
                                                                        }
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div
                                                        className={
                                                            styles.submit_wrap
                                                        }>
                                                        <span
                                                            className={
                                                                styles.save_result
                                                            }>
                                                            {updatingConfig ===
                                                            true ? (
                                                                <Spinner
                                                                    size={15}
                                                                />
                                                            ) : displaySuccess ? (
                                                                'Saved!'
                                                            ) : (
                                                                ''
                                                            )}
                                                        </span>
                                                        <AppButton
                                                            icon={SavedIcon}
                                                            onClick={
                                                                onNotificationsSave
                                                            }
                                                            width={'120px'}
                                                            isDisabled={
                                                                updatingConfig ||
                                                                (scanCompletionNotification &&
                                                                    !isScanCompletionNotificationEmailsValid) ||
                                                                (reportGenerationNotification &&
                                                                    !isReportGenerationNotificationEmailsValid)
                                                            }>
                                                            Save
                                                        </AppButton>
                                                    </div>
                                                </Pane>
                                            );
                                        default:
                                            return (
                                                <Pane
                                                    key={tab}
                                                    id={`panel-${tab}`}
                                                    role="tabpanel"
                                                    aria-labelledby={tab}
                                                    aria-hidden={
                                                        index !== selectedIndex
                                                    }
                                                    className={
                                                        styles.target_group_settings__pane__configuration
                                                    }
                                                    display={
                                                        index === selectedIndex
                                                            ? 'block'
                                                            : 'none'
                                                    }>
                                                    <table>
                                                        <colgroup>
                                                            <col
                                                                className={
                                                                    styles.setting_key
                                                                }
                                                            />
                                                            <col
                                                                className={
                                                                    styles.setting_value
                                                                }
                                                            />
                                                        </colgroup>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <div>
                                                                        <span
                                                                            className={
                                                                                styles.target_group_settings__pane__configuration__config_label
                                                                            }>
                                                                            Scan
                                                                            name
                                                                        </span>
                                                                        <p>
                                                                            Which
                                                                            scan
                                                                            this
                                                                            group
                                                                            belongs
                                                                            to
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <Link
                                                                        to={`/${companySlug}/scans/${assessmentId}`}>
                                                                        <span>
                                                                            {name
                                                                                ? name
                                                                                : 'unavailable'}
                                                                        </span>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div>
                                                                        <span
                                                                            className={
                                                                                styles.target_group_settings__pane__configuration__config_label
                                                                            }>
                                                                            Language
                                                                        </span>
                                                                        <p>
                                                                            Language
                                                                            of
                                                                            the
                                                                            scan,
                                                                            reports
                                                                            and
                                                                            emails
                                                                            for
                                                                            this
                                                                            group
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span>
                                                                        {language
                                                                            ? language.toUpperCase()
                                                                            : ''}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div>
                                                                        <span
                                                                            className={
                                                                                styles.target_group_settings__pane__configuration__config_label
                                                                            }>
                                                                            Expiration
                                                                            date
                                                                        </span>
                                                                        <p>
                                                                            This
                                                                            group
                                                                            is
                                                                            active
                                                                            until
                                                                            the
                                                                            expiration
                                                                            date.
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span>
                                                                        {targetGroup &&
                                                                        targetGroup.expiration_date
                                                                            ? `Ends on ${formatDate(
                                                                                  targetGroup.expiration_date,
                                                                              )}`
                                                                            : 'No Expiration Date'}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </Pane>
                                            );
                                    }
                                })}
                            </Pane>
                        </Pane>
                    </>
                )}>
                <Button style={{ height: '35px' }}>
                    <SettingsIcon size={20} />
                </Button>
            </Popover>
        </div>
    );
};
