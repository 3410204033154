import React, { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ChevronLeftIcon } from 'evergreen-ui';
import {
    Content,
    ContentContainer,
    ScanSetupHeaderBackWrapper,
    ScanSetupWrapper,
} from './style';
import FirstStep from './components/ScanStepsContent/FirstStep';
import SecondStep from './components/ScanStepsContent/SecondStep';
import ThirdStep from './components/ScanStepsContent/ThirdStep';
import { selectCompaniesState } from '../../store/companies/companies.selectors';
import { CompanyBase } from '../../typings/company';
import ForthStep from './components/ScanStepsContent/ForthStep';
import {
    Assessment,
    AssessmentCreationConfig,
    AssessmentModule,
} from '../../typings/assessment';
import {
    actions as assessmentsActions,
    actions as assessmentActions,
} from '../../store/assessments/assessments.actions';
import { CreateTargetGroupSchema } from '../../typings/target_group';
import {
    AssessmentModuleVideo,
    AssessmentModuleVideos,
    GroupSetupViewProps,
    MediaLibraryVideo,
    ScanLanguage,
} from '../../typings';
import {
    selectAssessmentsState,
    selectAssessmentsStateError,
} from '../../store/assessments/assessments.selectors';
import {
    selectTargetGroupState,
    selectTargetGroupStateError,
} from '../../store/targetGroups/targetGroups.selectors';
import { Skeleton } from '@material-ui/lab';
import { selectAuthState } from '../../store/auth/auth.selectors';
import { actions as targetGroupsActions } from '../../store/targetGroups/targetGroups.actions';
import SuccessPage from '../../components/SuccessPage/SuccessPage';

export interface AddTargetGroupStateSchema {
    name: string;
    assessment_id: string;
    is_public: boolean;
    auto_send_reports: boolean;
    verify_emails: boolean;
}

const ScanSetupView: React.FC<GroupSetupViewProps> = (
    props: GroupSetupViewProps,
) => {
    const { isGroupSetup, isInternal } = props;
    const dispatch = useDispatch();
    const { companies } = useSelector(selectCompaniesState);
    const targetGroupsFromStore = useSelector(selectTargetGroupState);
    const assessments = useSelector(selectAssessmentsState);
    const { isLoading } = useSelector(selectAssessmentsState);
    const createAssessmentError = useSelector(selectAssessmentsStateError);
    const createTargetGroupError = useSelector(selectTargetGroupStateError);
    const navigate = useNavigate();
    const { companySlug } = useParams();

    const [newAssessmentSection, setNewAssessmentSection] =
        useState<boolean>(false);
    const [existingAssessmentSection, setExistingAssessmentSection] =
        useState<boolean>(false);
    const [company, setCompany] = useState<CompanyBase | null>(null);
    const [targetGroupName, setTargetGroupName] = useState<string>('');
    const [scanName, setScanName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [assessment, setAssessment] = useState<Assessment>({
        id: '',
        company_id: '',
        name: '',
        created_at: '',
        updated_at: '',
        modules: [],
        is_active: false,
        language: '',
        data_collection: false,
    });
    const [language, setLanguage] = useState<ScanLanguage>('nl-NL');
    const [isCultureFitEnabled, setIsCultureFitEnabled] =
        useState<boolean>(false);
    const [
        isCultureFitQuestionnaireEnabled,
        setIsCultureFitQuestionnaireEnabled,
    ] = useState<boolean>(false);
    const [isCultureFitVideoEnabled, setIsCultureFitVideoEnabled] =
        useState<boolean>(false);
    const [cultureFitVideo, setCultureFitVideo] = useState<
        MediaLibraryVideo | undefined
    >({
        url: '',
        file: undefined,
        id: undefined,
    });
    const [isSpeakingEnabled, setIsSpeakingEnabled] = useState<boolean>(false);
    const [speakingWrittenQuestions, setSpeakingWrittenQuestions] =
        useState<boolean>(true);
    const [speakingVideo1, setSpeakingVideo1] = useState<
        MediaLibraryVideo | undefined
    >({
        url: '',
        file: undefined,
        id: undefined,
    });
    const [speakingVideo2, setSpeakingVideo2] = useState<
        MediaLibraryVideo | undefined
    >({
        url: '',
        file: undefined,
        id: undefined,
    });
    const [isCognitiveAbilitiesEnabled, setIsCognitiveAbilitiesEnabled] =
        useState<boolean>(false);
    const [cultureFitIsCompleted, setCultureFitIsCompleted] =
        useState<boolean>(false);
    const [speakingIsCompleted, setSpeakingIsCompleted] =
        useState<boolean>(false);
    const [scanCompletionNotification, setScanCompletionNotification] =
        useState<boolean>(false);
    const [
        scanCompletionNotificationEmails,
        setScanCompletionNotificationEmails,
    ] = useState<string>('');
    const [
        isScanCompletionNotificationEmailsValid,
        setScanCompletionNotificationEmailsValid,
    ] = useState(false);
    const [reportGenerationNotification, setReportGenerationNotification] =
        useState<boolean>(false);
    const [
        reportGenerationNotificationEmails,
        setReportGenerationNotificationEmails,
    ] = useState<string>('');
    const [
        isReportGenerationNotificationEmailsValid,
        setReportGenerationNotificationEmailsValid,
    ] = useState(false);

    const initialTargetGroupState = {
        name: targetGroupName,
        assessment_id: '',
        is_public: true,
        auto_send_reports: true,
        verify_emails: false,
    };

    const [targetGroup, setTargetGroup] = useState<AddTargetGroupStateSchema>(
        initialTargetGroupState,
    );

    const hasCultureFitVideo: AssessmentModule | undefined =
        assessment &&
        assessment.modules &&
        assessment.modules.find((module) => {
            return module.id === 'culture-fit';
        });
    const hasCultureFitQuestionnaire: AssessmentModule | undefined =
        assessment &&
        assessment.modules &&
        assessment.modules.find((module) => {
            return module.id === 'culture-fit-questionnaire';
        });
    const hasCultureFit: AssessmentModule | undefined =
        hasCultureFitVideo || hasCultureFitQuestionnaire;
    const hasSpeaking: AssessmentModule | undefined =
        assessment &&
        assessment.modules &&
        assessment.modules.find((module) => {
            return module.id === 'interview';
        });
    const hasSpeakingVideo: AssessmentModuleVideos | undefined =
        hasSpeaking &&
        hasSpeaking.questions &&
        hasSpeaking.questions.find((question) => {
            return !!question.videos;
        });
    const hasSpeakingVideo1: AssessmentModuleVideo | undefined =
        hasSpeaking && hasSpeaking.questions && hasSpeaking.questions[0].videos;
    const hasSpeakingVideo2: AssessmentModuleVideo | undefined =
        hasSpeaking && hasSpeaking.questions && hasSpeaking.questions[1].videos;
    const hasCognitiveAbilities: AssessmentModule | undefined =
        assessment &&
        assessment.modules &&
        assessment.modules.find((module) => {
            return module.id === 'mental-test';
        });

    const existingScansAndGroups: {
        scan: Assessment;
        groupsCount: number;
    }[] = useMemo(() => {
        return company &&
            company.id &&
            assessments.byCompanyId[company.id] &&
            assessments.byCompanyId[company.id].list
            ? assessments.byCompanyId[company.id].list.map((a) => ({
                  scan: a,
                  groupsCount:
                      targetGroupsFromStore.byAssessmentId[a.id] &&
                      targetGroupsFromStore.byAssessmentId[a.id].list
                          ? targetGroupsFromStore.byAssessmentId[a.id].list
                                .length
                          : 0,
              }))
            : [];
    }, [
        assessments.byCompanyId,
        company,
        targetGroupsFromStore.byAssessmentId,
    ]);

    useEffect(() => {
        if (companies.length > 0) {
            const company = companies.find(
                (company) => company.slug === companySlug,
            );
            if (company) {
                setCompany(company);
            }
        }
    }, [companies, companySlug]);

    const [section, setSection] = useState('FIRST');
    const goToStep = (userSection: string) => {
        setSection(userSection);
    };

    const { user } = useSelector(selectAuthState);

    useEffect(() => {
        if (
            company &&
            company.id &&
            assessments.byCompanyId[company.id] &&
            assessments.byCompanyId[company.id].list
        ) {
            assessments.byCompanyId[company.id].list.forEach((assessment) => {
                dispatch(
                    targetGroupsActions.getAssessmentsTargetGroups(
                        company.id,
                        assessment.id,
                    ),
                );
            });
        }
    }, [assessments.byCompanyId, company, dispatch]);

    useEffect(() => {
        if (user && user.company_id && company) {
            dispatch(
                assessmentsActions.getCompanyAssessments(company.id, true),
            );
        }
    }, [user, company, dispatch]);

    const scanAssessment: AssessmentCreationConfig = {
        name: scanName,
        language,
        cultureFit: {
            enabled: isCultureFitEnabled,
            type:
                isCultureFitQuestionnaireEnabled && isCultureFitVideoEnabled
                    ? 'FULL'
                    : isCultureFitVideoEnabled
                    ? 'VIDEO'
                    : 'QUESTIONNAIRE',
            videoType: 'CUSTOM',
            video: cultureFitVideo,
        },
        speaking: {
            enabled: isSpeakingEnabled,
            videoType: speakingWrittenQuestions ? 'NO_VIDEO' : 'CUSTOM',
            videos: [speakingVideo1, speakingVideo2],
        },
        cognitiveAbilities: {
            enabled: isCognitiveAbilitiesEnabled,
        },
        dataCollection: {
            enabled: false,
        },
    };

    const scanTargetGroup: CreateTargetGroupSchema = {
        name: targetGroupName,
        description: description,
        expiration_date: '2030-12-31T10:13:57.737Z',
        participants: [],
        config: {
            is_public: targetGroup.is_public,
            auto_send_reports: targetGroup.auto_send_reports,
            verify_emails: targetGroup.verify_emails,
            scan_completion_notification: scanCompletionNotification,
            scan_completion_notification_emails:
            scanCompletionNotificationEmails,
            report_generation_notification:
            reportGenerationNotification,
            report_generation_notification_emails:
            reportGenerationNotificationEmails,
        },
        type: isInternal ? 'INTERNAL' : 'CANDIDATE'
    };

    const onCreateScan = () => {
        if (!company || !company.id) {
            return;
        }

        dispatch(
            assessmentActions.createScan(
                company.id,
                isGroupSetup && existingAssessmentSection
                    ? undefined
                    : scanAssessment,
                isGroupSetup ? scanTargetGroup : undefined,
                isGroupSetup && existingAssessmentSection && assessment
                    ? assessment.id
                    : undefined,
                !isGroupSetup ? navigate : undefined,
                !isGroupSetup ? companySlug : undefined,
            ),
        );

        if (isGroupSetup) {
            goToStep('FINAL');
        } else {
            goToStep('NEW_SCAN_FINAL');
        }
    };

    return (
        <ScanSetupWrapper>
            {!isLoading && (
                <ScanSetupHeaderBackWrapper onClick={() => navigate('/')}>
                    <ChevronLeftIcon />
                    Back to dashboard
                </ScanSetupHeaderBackWrapper>
            )}
            {section === 'FIRST' && (
                <FirstStep
                    existingAssessmentSection={existingAssessmentSection}
                    goToNextStep={() => goToStep('SECOND')}
                    targetGroupName={targetGroupName}
                    setTargetGroupName={setTargetGroupName}
                    description={description}
                    setDescription={setDescription}
                    language={language}
                    setLanguage={setLanguage}
                    scanName={scanName}
                    setScanName={setScanName}
                    isGroupSetupWizard={isGroupSetup}
                />
            )}
            {section === 'SECOND' && (
                <SecondStep
                    goToPrevStep={() => goToStep('FIRST')}
                    goToNextStep={(step) => goToStep(step)}
                    isCultureFitEnabled={isCultureFitEnabled}
                    setIsCultureFitEnabled={setIsCultureFitEnabled}
                    isCultureFitQuestionnaireEnabled={
                        isCultureFitQuestionnaireEnabled
                    }
                    setIsCultureFitQuestionnaireEnabled={
                        setIsCultureFitQuestionnaireEnabled
                    }
                    isCultureFitVideoEnabled={isCultureFitVideoEnabled}
                    setIsCultureFitVideoEnabled={setIsCultureFitVideoEnabled}
                    isSpeakingEnabled={isSpeakingEnabled}
                    setIsSpeakingEnabled={setIsSpeakingEnabled}
                    isCognitiveAbilitiesEnabled={isCognitiveAbilitiesEnabled}
                    setIsCognitiveAbilitiesEnabled={
                        setIsCognitiveAbilitiesEnabled
                    }
                    language={language}
                    setLanguage={setLanguage}
                    setCultureFitIsCompleted={setCultureFitIsCompleted}
                    setSpeakingIsCompleted={setSpeakingIsCompleted}
                    setCultureFitVideo={setCultureFitVideo}
                    setSpeakingVideo1={setSpeakingVideo1}
                    setSpeakingVideo2={setSpeakingVideo2}
                    setSpeakingWrittenQuestions={setSpeakingWrittenQuestions}
                    newAssessmentSection={newAssessmentSection}
                    setNewAssessmentSection={setNewAssessmentSection}
                    existingAssessmentSection={existingAssessmentSection}
                    setExistingAssessmentSection={setExistingAssessmentSection}
                    companySlug={companySlug}
                    existingScansAndGroups={existingScansAndGroups}
                    assessment={assessment}
                    setAssessment={setAssessment}
                    isScanSetupWizard={isGroupSetup}
                />
            )}
            {section === 'THIRD' && (
                <ThirdStep
                    goToPrevStep={() => goToStep('SECOND')}
                    goToNextStep={() => goToStep('FORTH')}
                    companyId={company ? company.id : ''}
                    isCultureFitEnabled={isCultureFitEnabled}
                    isCultureFitQuestionnaireEnabled={
                        isCultureFitQuestionnaireEnabled
                    }
                    isCultureFitVideoEnabled={isCultureFitVideoEnabled}
                    isSpeakingEnabled={isSpeakingEnabled}
                    isCognitiveAbilitiesEnabled={isCognitiveAbilitiesEnabled}
                    cultureFitVideo={cultureFitVideo}
                    setCultureFitVideo={setCultureFitVideo}
                    speakingVideo1={speakingVideo1}
                    setSpeakingVideo1={setSpeakingVideo1}
                    speakingVideo2={speakingVideo2}
                    setSpeakingVideo2={setSpeakingVideo2}
                    speakingWrittenQuestions={speakingWrittenQuestions}
                    setSpeakingWrittenQuestions={setSpeakingWrittenQuestions}
                    cultureFitIsCompleted={cultureFitIsCompleted}
                    setCultureFitIsCompleted={setCultureFitIsCompleted}
                    speakingIsCompleted={speakingIsCompleted}
                    setSpeakingIsCompleted={setSpeakingIsCompleted}
                    newAssessmentSection={newAssessmentSection}
                    existingAssessmentSection={existingAssessmentSection}
                    isScanSetupWizard={isGroupSetup}
                />
            )}
            {section === 'FORTH' && (
                <ForthStep
                    goToPrevStep={(step) => goToStep(step)}
                    goToNextStep={onCreateScan}
                    isCognitiveAbilitiesEnabled={isCognitiveAbilitiesEnabled}
                    isSpeakingEnabled={isSpeakingEnabled}
                    isCultureFitVideoEnabled={isCultureFitVideoEnabled}
                    cultureFitIsCompleted={cultureFitIsCompleted}
                    speakingWrittenQuestions={speakingWrittenQuestions}
                    cultureFitVideo={cultureFitVideo}
                    speakingVideo1={speakingVideo1}
                    speakingVideo2={speakingVideo2}
                    targetGroupName={targetGroupName}
                    setTargetGroupName={setTargetGroupName}
                    description={description}
                    setDescription={setDescription}
                    language={language}
                    setLanguage={setLanguage}
                    scanName={scanName}
                    setScanName={setScanName}
                    setTargetGroup={setTargetGroup}
                    targetGroup={targetGroup}
                    newAssessmentSection={newAssessmentSection}
                    existingAssessmentSection={existingAssessmentSection}
                    assessment={assessment}
                    hasCultureFit={hasCultureFit}
                    hasSpeaking={hasSpeaking}
                    hasCognitiveAbilities={hasCognitiveAbilities}
                    hasCultureFitVideo={hasCultureFitVideo}
                    hasSpeakingVideo={hasSpeakingVideo}
                    hasSpeakingVideo1={hasSpeakingVideo1}
                    hasSpeakingVideo2={hasSpeakingVideo2}
                    hasCultureFitQuestionnaire={hasCultureFitQuestionnaire}
                    isScanSetupWizard={isGroupSetup}
                    isInternal={isInternal}
                    scanCompletionNotification={scanCompletionNotification}
                    setScanCompletionNotification={setScanCompletionNotification}
                    scanCompletionNotificationEmails={scanCompletionNotificationEmails}
                    setScanCompletionNotificationEmails={setScanCompletionNotificationEmails}
                    reportGenerationNotification={reportGenerationNotification}
                    setReportGenerationNotification={setReportGenerationNotification}
                    reportGenerationNotificationEmails={reportGenerationNotificationEmails}
                    setReportGenerationNotificationEmails={setReportGenerationNotificationEmails}
                    isScanCompletionNotificationEmailsValid={isScanCompletionNotificationEmailsValid}
                    setScanCompletionNotificationEmailsValid={setScanCompletionNotificationEmailsValid}
                    isReportGenerationNotificationEmailsValid={isReportGenerationNotificationEmailsValid}
                    setReportGenerationNotificationEmailsValid={setReportGenerationNotificationEmailsValid}
                />
            )}
            {!isLoading &&
                !createAssessmentError &&
                !createTargetGroupError &&
                section === 'FINAL' && (
                    <SuccessPage>
                        <Content>
                            <strong>
                                Your group has been successfully created
                            </strong>
                            <br />
                            <br />
                            <br />
                            <br />
                            <h5>
                                Click{' '}
                                <strong>
                                    <Link to={ !isInternal ? `/${companySlug}` : `/${companySlug}/internal`}>here</Link>
                                </strong>{' '}
                                to return to{' '}
                                <strong>{company ? company.name : ''}</strong>'s
                                {!isInternal ? ' candidate' : ' internal'} groups overview.
                            </h5>
                        </Content>
                    </SuccessPage>
                )}
            {((isLoading && section === 'FINAL') ||
                (isLoading && section === 'NEW_SCAN_FINAL')) && (
                <ContentContainer>
                    <Content>
                        Please wait while the scan is being created...
                        <br />
                        <br />
                        <Skeleton
                            variant="text"
                            width="100%"
                            height="25px"
                            animation="wave"
                        />
                        <br />
                    </Content>
                </ContentContainer>
            )}
            {createAssessmentError &&
                (section === 'FINAL' || section === 'NEW_SCAN_FINAL') && (
                    <ContentContainer>
                        <Content>
                            <br />
                            An error occurred during creating your scan. Please
                            try again.
                            <br />
                            <br />
                            Click <Link to={`/${companySlug}`}>here</Link> to
                            return to {company ? company.name : ''}'s group
                            overview.
                        </Content>
                    </ContentContainer>
                )}
            {createTargetGroupError &&
                !createAssessmentError &&
                section === 'FINAL' && (
                    <ContentContainer>
                        <Content>
                            <br />
                            An error occurred during creating your group. Please
                            try again.
                            <br />
                            <br />
                            Click <Link to={`/${companySlug}`}>here</Link> to
                            return to {company ? company.name : ''}'s group
                            overview.
                        </Content>
                    </ContentContainer>
                )}
        </ScanSetupWrapper>
    );
};

export default ScanSetupView;
